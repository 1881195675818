$primary: #1C7CD5;
$enable-responsive-font-sizes: true;
$font-family-sans-serif: 'PT Sans', 'Roboto', 'Segoe UI', Arial, sans-serif;
$font-size-base: 1.15rem;
$body-color: #373A3C;

$input-btn-font-size: 1rem;

$spinner-border-width: 0.2em;
$spinner-border-width-sm: 0.1em;

$modal-header-border-color: transparent;
$modal-footer-border-color: transparent;
$modal-header-border-width: 0px;
$modal-footer-border-width: 0px;

$modal-header-padding: 2.5rem 3rem .5rem 3rem;
$modal-inner-padding: .5rem 3rem 3.5rem;

@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/suggestions-jquery/dist/css/suggestions";

.modal-header .close {
  padding: 0;
  margin: 0;
}

.card-columns {
  @include media-breakpoint-only(xs) {
    column-count: 1;
  }
  @include media-breakpoint-only(sm) {
    column-count: 2;
  }
  @include media-breakpoint-only(md) {
    column-count: 2;
  }
  @include media-breakpoint-only(lg) {
    column-count: 2;
  }
  @include media-breakpoint-only(xl) {
    column-count: 2;
  }
}

@media(min-width: map-get($grid-breakpoints, sm)) {
  .w-sm-50 {
    width: 50% !important;
  }
}

@media(min-width: map-get($grid-breakpoints, lg)) {
  .w-lg-25 {
    width: 25% !important;
  }
}

body.loading {
  z-index: 1;
  overflow: hidden;

  &::before {
    content: '';
    position: fixed;
    left: 50%;
    top: 50%;
    width: 100px;
    height: 100px;
    margin-left: -50px;
    margin-top: -50px;
    box-shadow: 0 2px 6px -3px rgba(0,0,0,.8);
    border-radius: 10px;
    // left: 0;
    // top: 0;
    // right: 0;
    // bottom: 0;
    background: #fff;
    z-index: 999998;
  }

  &::after {
    content: '';
    box-sizing: border-box;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin-top: -30px;
    margin-left: -30px;
    border-radius: 50%;
    border: 3px solid #ccc;
    border-top-color: #07d;
    animation: spinner .6s linear infinite;
    z-index: 999999;
  }

}

.container {
  max-width: 960px;
}

.custom-checkbox.custom-checkbox-image {
  .custom-control-input {
    & ~ .custom-control-label img {
      width: auto;
      height: 64px;
      opacity: 0.4;
    }
    &:checked ~ .custom-control-label img {
      opacity: 1
    }
  }
}

.text-capitalize-first::first-letter {
  text-transform: capitalize;
}

// Структурные элементы

.header {
  background: #FDFDFD;

  .logo {
    width: auto;
    height: 72px;
  }

}

.content {

}

.subtotal {
  background: #F7F7F7;
}

.footer {
  background: #F2F2F2;
}

.recaptcha {
  background: #E5E5E5;
}

// Анимации

.fade-enter-active, .fade-leave-active {
  transition: opacity .4s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all .4s ease;
}

.slide-fade-leave-active {
  transition: all .4s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(-30px);
  opacity: 0;
}

// Загрузка...
@keyframes spinner {
  to {transform: rotate(360deg);}
}
